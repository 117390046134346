body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background: repeating-linear-gradient(
    -45deg,
    #eaeaea,
    #eaeaea 10px,
    #e1e1e1 10px,
    #e1e1e1 20px
  );
  letter-spacing: -1px;
}
